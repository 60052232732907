import { graphql } from "gatsby"
import React from "react"
import { Appear, Section, SEO } from "../components"
import { JobOffer } from "../models/JobOffer"
import { Page } from "../models/Page"

interface Props {
  data: {
    jobOffer: JobOffer
  }
}

const JobOfferPage = (props: Props & Page) => {
  const { jobOffer } = props.data

  return (
    <>
      <SEO seoMetaTags={jobOffer.seoMetaTags} />
      <article className="max-w-screen-xl mx-auto">
        <header className="container">
          <div className="pb-16">
            <Appear order={1}>
              <h1 className="heading-l lg:heading-xl mb-2">{jobOffer.title}</h1>
            </Appear>

            <Appear order={2}>
              <div className="lg:paragraph-s">{jobOffer.studio.city}</div>
            </Appear>
          </div>
        </header>
        {jobOffer.content &&
          jobOffer.content.map((section, i) => {
            return <Section key={section.id} section={section} order={i} />
          })}
      </article>
    </>
  )
}

export const query = graphql`
  query JobOfferPageQuery($slug: String!, $locale: String!) {
    jobOffer: datoCmsJobOffer(slug: { eq: $slug }, locale: { eq: $locale }) {
      slug
      studio {
        city
      }
      title
      content {
        __typename
        ...TextFragment
        ...Text2colFragment
      }
      seoMetaTags {
        tags
      }
    }
  }
`

export default JobOfferPage
